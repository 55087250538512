import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { settingsStore } from "../../store/settingsStore";
import { GlobalStyle } from "../globalStyle";
import { createTheme } from "../index";

export const ThemeProviderWraper = ({ children }) => {
  const [direction, mode, responsiveFontSizes] = settingsStore((state) => [
    state.direction,
    state.mode,
    state.responsiveFontSizes,
  ]);

  const theme = createTheme({
    direction,
    mode,
    responsiveFontSizes,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      <Box >{children}</Box>
    </ThemeProvider>
  );
};
