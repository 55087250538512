import { Visibility, VisibilityOff } from "@mui/icons-material";
import TranslateIcon from "@mui/icons-material/Translate";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { TextFieldStyled } from "components/styled/TextField";
import { NavLink } from "react-router-dom";
import AuthLayOut from "./components/AuthLayOut";
import { useLogin } from "./hooks/useLogin";

const Login = () => {
  const {
    t,
    errors,
    loading,
    onSubmit,
    register,
    direction,
    toggleLang,
    handleSubmit,
    showPassword,
    handleTogglePasswordVisibility,
  } = useLogin();

  // const access_token = _AuthApi.getToken();
  // const setCount = unreadNotifications((state) => state.setCount);

  // useQuery({
  //   queryKey: ["unread-notifications", access_token],
  //   enabled: !!access_token,
  //   queryFn: async () => {
  //     const { data } = await _axios.get("/user/notification/unread");
  //     return data.unread_count;
  //   },
  //   onSuccess: (data) => {
  //     setCount(data);
  //   },
  // });
  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        height: "100vh",
        overflow: { xs: "auto", lg: "hidden" },
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <AuthLayOut />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          width: { xs: "100%", lg: "50%" },
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            minWidth: "350px",
            width: "100%",
            padding: "50px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 5,
              left: 0,
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={toggleLang}
              sx={{
                backgroundColor: "transparent",
                color: "black",
                p: 1,
                m: 1,
              }}
            >
              <TranslateIcon />
            </IconButton>
            <Typography sx={{ fontSize: "12px", color: "black" }}>
              {direction === "ltr" ? "العربية" : "En"}
            </Typography>
          </Box>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant=""
              sx={{ color: "black", fontSize: "20px", fontWeight: "800" }}
            >
              {t("hi, welcome back")}
            </Typography>
            <Typography
              variant=""
              sx={{ color: "#bebebe", mt: "10px", textAlign: "center" }}
            >
              {t("enter your credentials to continue")}
            </Typography>
          </Grid>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              width: "100%",
              diplay: "flex",
              alignItems: "center",
              justifyContent: "cetner",
            }}
          >
            <Box sx={{ width: "100%", mt: "20px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle" sx={{ color: "black" }}>
                  {t("email")}
                </Typography>
              </Box>
              <TextFieldStyled
                type="email"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": { color: "black" },
                }}
                placeholder={t("enter your email")}
                {...register("email", { validate: true })}
                error={errors.email?.message}
                helperText={errors.email?.message || ""}
              />
            </Box>
            <Box sx={{ width: "100%", mt: "20px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle" sx={{ color: "black" }}>
                  {t("password")}
                </Typography>
              </Box>
              <TextFieldStyled
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": { color: "black" },
                }}
                type={showPassword ? "text" : "password"}
                placeholder={t("enter your password")}
                {...register("password")}
                error={errors.password?.message}
                helperText={errors.password?.message || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ backgroundColor: "transparent" }}
                        onClick={handleTogglePasswordVisibility}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            sx={{
                              color: "#637381",
                            }}
                          />
                        ) : (
                          <Visibility
                            sx={{
                              color: "#637381",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 5,
              }}
            >
              <Box>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "origin.main",
                      width: 130,
                      borderRadius: "10px",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 1,
                      }}
                    />
                  </Box>
                ) : (
                  <Button
                    sx={{
                      color: "white",
                      backgroundColor: "origin.main",
                      "&:hover": { backgroundColor: "origin.hover" },
                      width: 130,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      lineHeight: 0,
                      py: 2.5,
                    }}
                    disabled={loading}
                    fullWidth
                    type="submit"
                    variant=""
                  >
                    {t("sign in")}
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <NavLink to="/reset-password">
                  <Typography
                    variant=""
                    sx={{
                      color: "black",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("reset password")}
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
