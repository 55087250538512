import loadable from "@loadable/component";
import React from "react";

// ============ Auth ============
export const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
export const PasswordEditForm = React.lazy(() =>
  import("pages/PasswordEditForm")
);
export const VerificationCodeForm = React.lazy(() =>
  import("pages/VerificationCodeForm")
);

// ============ Auth ============

// ============ Modules ============

export const AdminRouting = loadable(() =>
  import("modules/admin/AdminRouting")
);
export const RoleRouting = loadable(() =>
  import("modules/role-permission/RoleRouting")
);
export const SystemInformationRouting = loadable(() =>
  import("modules/system_information/SystemInformationRouting")
);
export const LevelRouting = loadable(() =>
  import("modules/level/LevelRouting")
);
export const SubjectRouting = loadable(() =>
  import("modules/subject/SubjectRouting")
);
export const GradeRouting = loadable(() =>
  import("modules/grade/GradeRouting")
);
export const CountryRouting = loadable(() =>
  import("modules/country/CountryRouting")
);
export const CityRouting = loadable(() => import("modules/city/CityRouting"));
export const AreaRouting = loadable(() => import("modules/area/AreaRouting"));
export const BranchRouting = loadable(() =>
  import("modules/branch/BranchRouting")
);
export const YearRouting = loadable(() => import("modules/year/YearRouting"));
export const SchoolRouting = loadable(() =>
  import("modules/school/SchoolRouting")
);
export const SectionRouting = loadable(() =>
  import("modules/section/SectionRouting")
);
export const ResidentialAreasRouting = loadable(() =>
  import("modules/residential-area/ResidentialAreasRouting")
);
export const AcademicYearRouting = loadable(() =>
  import("modules/academic_year/AcademicYearRouting")
);
export const AcademicProgramRouting = loadable(() =>
  import("modules/academic_program/AcademicProgramRouting")
);
export const HolidayRouting = loadable(() =>
  import("modules/holiday/HolidayRouting")
);
export const TuitionFeesRouting = loadable(() =>
  import("modules/tuition_fee/TuitionFeesRouting")
);
export const DiscountsRouting = loadable(() =>
  import("modules/discount/DiscountsRouting")
);
export const LanguagesRouting = loadable(() =>
  import("modules/language/LanguageRouting")
);
export const ReligionRouting = loadable(() =>
  import("modules/religion/ReligionRouting")
);
export const MedicalConditionRouting = loadable(() =>
  import("modules/medical_condition/MedicalConditionRouting")
);
export const StudentMainInfoRouting = loadable(() =>
  import("modules/students/main_information/StudentMainInfoRouting")
);
export const ParentRouting = loadable(() =>
  import("modules/parent/ParentRouting")
);
export const MilitaryServiceRouting = loadable(() =>
  import("modules/military_service/MilitaryServiceRouting")
);
export const EducationStatusRouting = loadable(() =>
  import("modules/education_status/EducationStatusRouting")
);
export const AbouUsRouting = loadable(() =>
  import("modules/system_information/about-us/AbouUs")
);
export const PrivacyPolicy = loadable(() =>
  import("modules/system_information/privacy-policy/PrivacyPolicy")
);
export const FaqRouting = loadable(() => import("modules/faq/FaqRouting"));
export const BusRouting = loadable(() => import("modules/bus/BusRouting"));
export const EmployeeRouting = loadable(() =>
  import("modules/employee/EmployeeRouting")
);
export const CurriculumRouting = loadable(() =>
  import("modules/curriculum/CurriculumRouting")
);
export const AssessmentTypeRouting = loadable(() =>
  import("modules/assessment_type/AssessmentTypeRouting")
);
export const BranchCurriculumRouting = loadable(() =>
  import("modules/branch_curriculum/BranchCurriculumRouting")
);
export const TeacherAllocationRouting = loadable(() =>
  import("modules/teacher_allocation/TeacherAllocationRouting")
);

export const SessionSettingRouting = loadable(() =>
  import("modules/session_setting/SessionSettingRouting")
);
export const WeeklySectionScheduleRouting = loadable(() =>
  import("modules/weekly_section_schedule/WeeklySectionScheduleRouting")
);

// ============ Modules ============

// ============ DashboardRouting ============
export const DashboardRouting = loadable(() =>
  import("../modules/dashboard/DashboardRouting")
);
// ============ DashboardRouting ============
