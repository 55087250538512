import { Box } from "@mui/material";
import AuthImage from "assets/images/AuthBg.jpg";

const AuthLayOut = () => {
  return (
    <Box sx={{ width: { xs: "100%", lg: "80%" }, height: "100%" }}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <img
          src={AuthImage}
          loading="lazy"
          alt=""
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
    </Box>
  );
};

export default AuthLayOut;
